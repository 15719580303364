<template>
  <div class="order">
    <c-basket
      moduleName="checkout"
      :translationsPath="tPath"
    >
      <template #summaryfooter>
        <slot name="summaryfooter" v-bind="{ basketId: basket.id, submit }"/>
      </template>
    </c-basket>

    <div class="order-configuration">
      <h2 class="configuration-title">{{ $t(`${tPath}.configuration.delivery.title`) }}</h2>

      <dl class="configuration-list">
        <!-- Lieferung -->
        <template v-if="orderConfiguration.deliveryType === 'Delivery'">
          <template v-if="orderConfiguration.deliveryAddress">
            <dt>{{ $t(`${tPath}.configuration.delivery.deliveryaddress.title`) }}</dt>
            <dd><c-checkout-location-address :predefined="{ location: orderConfiguration.location, address: orderConfiguration.deliveryAddress }"/></dd>
          </template>

          <template v-if="orderConfiguration.deliveryComments">
            <dt>{{ $t(`${tPath}.configuration.delivery.comments.title`) }}</dt>
            <dd>{{ orderConfiguration.deliveryComments }}</dd>
          </template>

          <template v-if="orderConfiguration.deliveryReference">
            <dt>{{ $t(`${tPath}.configuration.delivery.reference.title`) }}</dt>
            <dd>{{ orderConfiguration.deliveryReference }}</dd>
          </template>
        </template>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Order',
  computed: {
    order () {
      return this.$store.getters['checkout/get']
    },
    basket () {
      return this.$store.getters['checkout/getBasket']
    },
    orderConfiguration () {
      return Object.assign({}, this.basket.configuration || {}, this.$store.getters['checkout/getConfiguration'] || {})
    },
    orderValidation () {
      return this.$store.getters['checkout/getValidation']
    },
    orderErrors () {
      return this.orderValidation.validationErrors || []
    }
  },
  methods: {
    submit (e) {
      e.preventDefault()

      this.$store.dispatch('checkout/execute')
        .then(response => {
          if (this.order.isOrderValid) window.location.assign(e.target.href)
        })
    }
  }
}
</script>

<style lang="scss">
$order-configuration-title-padding-y: $spacer * 0.85 !default;
$order-configuration-title-padding-x: $spacer * 1 !default;
$order-configuration-title-bg: $light !default;
$order-configuration-title-font-size: $font-size-lg !default;
$order-configuration-title-font-weight: $font-weight-bold !default;
$order-configuration-title-color: inherit !default;

$order-configuration-list-gap: $spacer * 3 !default;
$order-configuration-list-padding-y: $spacer * 0.5 !default;
$order-configuration-list-padding-x: $order-configuration-title-padding-x !default;
$order-configuration-list-border: map-get($borders, 'base') !default;
$order-configuration-list-title-font-size: $font-size-sm !default;
$order-configuration-list-title-font-weight: inherit !default;
$order-configuration-list-title-color: $dark !default;
$order-configuration-list-data-font-size: inherit !default;
$order-configuration-list-data-font-weight: inherit !default;
$order-configuration-list-data-color: inherit !default;
$order-configuration-list-data-content-gap: $spacer * 0.3 !default;

.order {
  .order-configuration {
    .configuration-title {
      margin: 0;
      padding: $order-configuration-title-padding-y $order-configuration-title-padding-x;
      background-color: $order-configuration-title-bg;
      font-size: $order-configuration-title-font-size;
      font-weight: $order-configuration-title-font-weight;
      color: $order-configuration-title-color;
    }

    .configuration-list {
      @include make-row(0);
      margin-bottom: $order-configuration-list-gap;
      border-top: $order-configuration-list-border;

      dt,
      dd {
        @include make-col-ready(0);
        margin: 0;
        padding: $order-configuration-list-padding-y $order-configuration-list-padding-x;
        border-bottom: $order-configuration-list-border;
      }

      dt {
        @include make-col(3);
        font-size: $order-configuration-list-title-font-size;
        font-weight: $order-configuration-list-title-font-weight;
        color: $order-configuration-list-title-color;
      }

      dd {
        @include make-col(9);
        font-size: $order-configuration-list-data-font-size;
        font-weight: $order-configuration-list-data-font-weight;
        color: $order-configuration-list-data-color;

        > * {
          margin-bottom: $order-configuration-list-data-content-gap;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
